<template>
  <CartInfoCard
    :infoValue="value"
    :text="text"
    :imgSrc="serviceImg"
    :editable="editable"
    @edit="edit"
  />
</template>
<script>
import CartInfoCard from "./CartInfoCard.vue";
export default {
  name: "CartInfoServiceCard",
  props: {
    shippingAddress: { type: Object, required: true },
    editable: { type: Boolean, default: false }
  },
  components: { CartInfoCard },
  computed: {
    value() {
      return this.$t(
        "navbar.service." + this.shippingAddress.deliveryServiceId + ".name",
        this.shippingAddress
      );
    },
    text() {
      return this.$t("navbar.serviceSelected");
    },
    serviceIcon() {
      return (
        "$" +
        this.$t(
          "navbar.service." +
            this.shippingAddress.deliveryServiceId +
            ".fontIcon"
        )
      );
    },
    serviceImg() {
      return this.$t(
        "navbar.service." + this.shippingAddress.deliveryServiceId + ".icon"
      );
    }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
